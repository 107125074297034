import ApiError from "./error";
import axios from "axios";
import mime from "mime";
import { BASE_API, BASE_API_AUTH, AI_KEY, AI_API, AI_API_CHAT, ASSISTANT_API } from "@env";
import { NativeModules } from "react-native";
export const api = BASE_API;
export const apiAuth = BASE_API_AUTH;
// export const api = 'http://localhost:8003'; 
// export const apiAuth = 'http://localhost:8002';
// export const api = 'https://api-iqops.iqkitchen.biz'; 
// export const apiAuth = 'https://auth-iqops.iqkitchen.biz';
// export const api = "https://iqops-uat.iqkitchen.biz";
// export const apiAuth = "https://auth-uat.iqkitchen.biz";
// export const api = 'https://inventory-api.iqkitchen.biz'; 
// export const apiAuth = 'https://inventory-auth.iqkitchen.biz';
export const aiKey = AI_KEY;
export const aiApi = AI_API;
export const aiApiChat = AI_API_CHAT;

import {Platform} from 'react-native';
const apiURL = api + "/api/v1";
import base64 from "base-64";
import Swal from "sweetalert2";

import { logout, authTokensUpdated } from "../actions/login";
import { SET_FORMS_FROM_API, SET_FORMS_LOADED, SET_FORMS_LOADED_END } from "../constants/actions";
import { createAction } from '@reduxjs/toolkit'
import colors from "../constants/colors";

let refreshingToken = false;

export function apiCall(dispatch, getState, method, path, body, opts) {
  return apiC(apiURL, dispatch, getState, method, path, body, true);
}

export function apiAuthCall(dispatch, getState, method, path, body, opts) {
  return apiC(apiAuth, dispatch, getState, method, path, body, false);
}

export function apiC(url, dispatch, getState, method, path, body, auth) {
  const fetchOpts = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    json: true,
    timeout: 15000, // 15 seconds
  };

  if (auth) {
    const token = getState().user.get("authToken");
    fetchOpts.headers.Authorization = "Bearer " + token;
  }

  if (body) {
    fetchOpts.body = JSON.stringify(body);
  }
  // console.log(fetchOpts)
  return fetch(url + path, Object.assign({}, {}, fetchOpts))
    .then((response) => {
      if (response && (response.status < 200 || response.status >= 300)) {
        return response.json().then(
          function (response) {
            if (typeof response.error === "string") {
              if(response.error == "authentication_failed"){
                if (Platform.OS === 'web') {
                  alertConfirm("Authentication Expired", "OK");
                  //Wait to alert be displayed
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 1000);
                } else {
                  dispatch(logout());
                  NativeModules.DevSettings.reload();
                }
              }else{
                alertConfirm("Server error " + response.error, "OK");
              }
              throw new ApiError(response);
            }
            throw new Error(`HTTP Error ${response.status}`);
          },
          function (error) {
            if (error.isApiError) {
              throw error;
            }
            throw new Error(`HTTP Error ${response.status}`);
          }
        );
      }
      
     /* if(body?.getAllResponse){
        console.log(JSON.stringify( response.json() ))
        return response;
      }
       */ 
      
      return response.json();
    })
    .catch((error) => {
      if (error.isApiError && error.reason === "token_expired") {
        if (!refreshingToken) {
          return refreshAuthToken(dispatch, getState).then(() =>
            apiC(...arguments)
          );
        }
      } else if (
        error.isApiError &&
        (error.reason === "token_not_found" ||
          error.reason === "authentication_failed")
      ) {
        dispatch(logout());
      } else {
        throw error;
      }
    });
}
function alertConfirm(message, confirmation){
  Swal.fire({
    text: message,
    confirmButtonText: confirmation,
    confirmButtonColor: colors.orange,
    backdrop: false
  });

  return;
}

export function refreshAuthToken(dispatch, getState) {
  refreshingToken = true;
  const token = getState().user.get("refreshToken");

  if (!token) {
    throw new Error("No refresh token");
  }

  return apiAuthCall(dispatch, getState, "POST", "/auth/refresh", { token })
    .then((data) => {
      //setHost(data.token);
      dispatch(authTokensUpdated(data));
    })
    .catch((error) => {
      // Log out if invalid token
      if (error.reason === "token_not_found") {
        dispatch(logout());
      } else {
        throw error;
      }
    })
    .finally(() => {
      refreshingToken = false;
    });
}

export function requestLogin(dispatch, getState, creds) {
  return apiAuthCall(dispatch, getState, "POST", "/auth/login", {
    email: creds.email,
    password: creds.password,
  }).then((data) => {
    //setHost(data.token);
    dispatch(authTokensUpdated(data));
    return data;
  });
}

export function payWithBambora(params, callback = null) {
  return (dispatch, getState) => { 
    return apiCall(dispatch, getState, "POST", "/payment", params)
    .then( resp => {
      if(callback)
        callback(resp);
    });
  }
}

export function callAssistantApi(params, callback = null){
  return (dispatch, getState) => {
    return apiC(ASSISTANT_API, dispatch, getState, "POST", "/api/v1/assistant", params, true)
      .then( resp => {      
        if(callback) callback(resp);
      });
  }
}
export function requestCompletionApi(params, callback = null){
  return (dispatch, getState) => {
    return apiC(ASSISTANT_API, dispatch, getState, "POST", "/api/v1/chat/completions", params, true)
      .then( resp => {      
        if(callback) callback(resp);
      });
  }
}
export function requestMenu(dispatch, getState) {
  return apiCall(dispatch, getState, "GET", "/forms");
}

export function sendForm(dispatch, getState, dataSent, id, uuid) {
  dataSent.submission.status = "";
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/forms/submit/" + id,
    dataSent
  ).then((data) => {
    return data;
  });
}

export function syncErrorUpload(dispatch,getState,dataSent) {
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/submissions/syncerror",
    dataSent
  );
}

export function requestForm(dispatch, getState, id, idSubmission) {
  return apiCall(dispatch, getState, "GET", "/forms/retrieve/" + id + "/" + idSubmission).then((data) => {
    return data;
  });
}

export function uploadMediaB(dispatch, getState, data, uri) {
  
  const token = getState().user.get("authToken");
  let form = new FormData();
  let uriAndroid=uri;
  let extension = uri.substring(uri.lastIndexOf(".") + 1)?uri.substring(uri.lastIndexOf(".") + 1):"";
  
  if(Platform.OS==='ios'){
    form.append("image", {
      uri: uri,
      type: data,
      name: "image."+extension,
    });
  }
  if(Platform.OS==='android'){
    form.append("image", {
      uri: uriAndroid,
      type: mime.getType(uriAndroid),
      name: uriAndroid.split("/").pop(),
    });
  }
  
  if(Platform.OS==='web'){
    
    form.append("image", {
      uri: uri,
      type: "image/png",
      name: "image.png",
    });
  }

  
  return axios({
    method: "post",
    url: api + "/api/v1/media/uploadExpo",
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

export function uploadMedia(dispatch, getState = null, data, uri, name, authToken) {
  const token = getState ? getState().user.get("authToken") : authToken;
  const extension = name.substring(name.lastIndexOf(".") + 1);
  let form = new FormData();
  //const fileName = uri.split('/').pop();
  const fileName = name.replace(/\.[^/.]+$/, "");
  const fileType = mime.getType(uri);
  
  const byteString = atob(uri.split(',')[1]);
  
  const ab = new ArrayBuffer(byteString.length);
  const arr = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++)
    arr[i] = byteString.charCodeAt(i);

  const blob = new Blob([arr], {
    type: fileType,
  });

  const file = new File([blob], `${fileName}.${extension}`);

  form.append("document", file);
  
  return axios({
    method: "post",
    url: api + "/api/v1/media/uploadmedia",
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

export function uploadDocument(dispatch, getState, data, uri) {
  const token = getState().user.get("authToken");

  let form = new FormData();
  let uriAndroid=uri;
  let extension = uri.substring(uri.lastIndexOf(".") + 1);
  if(Platform.OS==='ios'){
    form.append("document", {
      uri: uri,
      type: mime.getType(uriAndroid),
      name: "document."+extension,
    });
  }else{
    form.append("document", {
      uri: uriAndroid,
      type: mime.getType(uriAndroid),
      name: uriAndroid.split("/").pop(),
    });
  }

  console.log('FORM =>'+JSON.stringify(form))
  return axios({
    method: "post",
    url: api + "/api/v1/media/uploaddocumentexpo",
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

export function setFileToFormTemplate(dispatch, getState, params){
  return apiCall(dispatch, getState, "POST", `/formbuilder/set_file_section`, params);
}

export function triggerTask(dispatch, getState, params){
  return apiCall(dispatch, getState, "POST", `/schedules/triggered`, params);
}

export function getApiPath() {
  return api;
}

export function getImage(url) {
  return RNFetchBlob.fetch("GET", api + url).then((response) => {
    return "data:image/png;base64," + response.base64();
  });
}

export function requestRegister(dispatch, getState, user_data) {
  return apiAuthCall(dispatch, getState, "POST", "/register", user_data).then(
    (data) => {
      return data;
    }
  );
}

export function getAllTasks(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/tasks", {}).then((data) => {
    return data;
  });
}

export function getLogTotalTasks(dispatch, getState) {
  return apiCall(dispatch, getState, "GET", "/loggroups/total_tasks").then((data) => {
    console.log(data)
    return data;
  });
}

export function getPromptCategories(dispatch, getState) {
  return apiCall(dispatch, getState, "GET", "/forms/get_prompt_categories").then((data) => {
    return data;
  });
}
export function getAiCategoriesAndPrompts(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/prompt/getAiCategoriesAndPrompts").then((data) => {
    return data;
  });
} 
export function editCategoriesVisibility(dispatch, getState, data) {
  return apiCall(dispatch, getState, "POST", "/prompt/editCategoriesVisibility", data).then((data) => {
    return data;
  });
}
/*
export function getAllTaskByLog(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/loggroups/taskbylog", {}).then( data => {
    return data;
  });
}



export function getAllRecords(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/records", {}).then((data) => {
    return data;
  });
}
*/

export function getAllTaskByLog(dispatch, getState, {page, filter = false}) {
  return apiCall(dispatch, getState, "POST", "/loggroups/taskbylog", {page, getAllResponse:true, ... ( filter &&  {status: filter} )}).then( response => {
    return response;
  });
}


export function getAllRecords(dispatch, getState, { page = false, filter = false } ) {
  if( filter ){
    return apiCall(dispatch, getState, "POST", `/records/filter/status/${filter}`, { ... ( page && { page }) } ).then( data => data );
  }
  else{
    if(page){
      return apiCall(dispatch, getState, "POST", "/records", {page, getAllResponse:true }).then(response => {
        return response;
      });
    }
    else{
      return apiCall(dispatch, getState, "POST", "/records", {}).then((data) => {
        return data;
      });
    }
  }
}


export function recordInProgress(dispatch, getState,record) {
  return apiCall(dispatch, getState, "POST", "/records/status/inprogress", record).then((data) => {
    return data;
  });
}

export function recordComplete(dispatch, getState,record) {    
  return apiCall(dispatch, getState, "POST", "/records/status/complete", record).then((data) => {
    return data;
  });
}

export function recordEditRequired(dispatch, getState,record) {
  return apiCall(dispatch, getState, "POST", "/records/status/editrequired", record).then((data) => {
    return data;
  });
}

export function recordApproved(dispatch, getState,record) {
  return apiCall(dispatch, getState, "POST", "/records/status/approved", record).then((data) => {
    return data;
  });
}

export function archiveTask(dispatch, getState, task) {
  return apiCall(dispatch, getState, "POST", "/tasks/save", task).then(
    (data) => {
      return data;
    }
  );
}
export function archiveOverdueTasks(dispatch, getState, task) {
  return apiCall(dispatch, getState, "POST", "/tasks/clearall", task).then(
    (data) => {
      return data;
    }
  );
}
export function saveTasks(dispatch, getState, task, fromLogs = true) {
    return apiCall(dispatch, getState, "POST", "/tasks/save", task).then(
      (data) => {
        return data;
      }
    );
}
export function getForms(dispatch, getState, task) {
  return apiCall(dispatch, getState, "POST", "/formbuilder/get", {}).then(
    (data) => {
      return data;
    }
  );
}

export async function getForms2(dispatch, getState, page = 1) {
  const pageSize = 10;
    try {
      const response = await apiCall(dispatch, getState, "POST", `/formbuilder/get_page_preview?page=${page}&page_size=${pageSize}`, {});
      dispatch(createAction(SET_FORMS_FROM_API)(response)); 
      if(response.forms.length == pageSize) {
        dispatch(createAction(SET_FORMS_LOADED)(true));
        await getForms2(dispatch, getState, page + 1);
      } else {
        dispatch(createAction(SET_FORMS_LOADED)(true));
        dispatch(createAction(SET_FORMS_LOADED_END)(true));
      }
      return response;
    } catch (error) {
      console.error(error);
    }
}


export function getLogGroups(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/loggroups/get", {}).then(
    (data) => {
      return data;
    }
  );
}
export function getLogGroupsSub(dispatch, getState, id) {
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/submissions/logroupentries/" + id,
    {}
  ).then((data) => {
    return data;
  });
}
export function loadGroupDetail(dispatch, getState, id) {
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/loggroups/getsingle/" + id,
    {}
  ).then((data) => {
    return data;
  });
}
export function signTask(dispatch, getState, data) {
  return apiCall(dispatch, getState, "POST", "/tasks/signtask", data).then(
    (data) => {
      return data;
    }
  );
}

export function removeTask(dispatch, getState, data) {
  return apiCall(dispatch, getState, "POST", "/tasks/remove", data).then(
    (data) => {
      return data;
    }
  );
}
export function insertBase64(dispatch, getState, base64) {
  console.log('si llega al base64')
  return apiCall(dispatch, getState, "POST", "/media/insertbase64", {base64});
}
export function submitIssue(dispatch, getState, submissionId, issues, IssueSolved) {
  return apiCall(dispatch, getState, "POST", `/submissions/issue/${submissionId}`, {issues, IssueSolved});
}

export function resetPassword(dispatch, getState, email) {
	
	return apiAuthCall(dispatch, getState, 'GET', `/reset/password/${email.email}`).then(data => {
	  return data;
	});
}
export function getRecordStatus(dispatch, getState, id) {
  return apiCall(dispatch, getState, "GET", `/record/getstatus/${id}`).then(
    (data) => {
      return data;
    }
  );
}
export function getLogStatus(dispatch, getState, id) {
  return apiCall(dispatch, getState, "GET", `/loggroups/logStatus/${id}`).then(
    (data) => {
      return data;
    }
  );
}
export function editDefaultData(dispatch, getState, data) {
  return apiCall(dispatch, getState, "POST", `/formbuilder/editdefaultdata`, data).then(
    (data) => {
      return data;
    }
  );
}
export function saveForm(dispatch, getState, services) {
  return apiCall(dispatch, getState, "POST", "/formbuilder/save", services);
}
export function getMyInventoryTypes(dispatch, getState, data){
  return apiCall(dispatch,getState,'POST',`/inventory/getMyInventoryTypes`, data);
}
export function getMyItems(dispatch, getState, data){
  return apiCall(dispatch,getState,'POST',`/inventory/myItems`, data);
}
export function getMyPackagingMaterials(dispatch, getState, data){
  return apiCall(dispatch,getState,'POST',`/material/myPackagingMaterials`, data);
}
//Received Items
export function getIngredientLotsByName(dispatch, getState, data){
  return apiCall(dispatch,getState,'POST',`/receivingIngredients/getItemByName`, data);
}
//Search customers
export function getMyCustomers(dispatch, getState, data){
  return apiCall(dispatch,getState,'POST',`/customer/myCustomers`, data);
}

//Suppliers
export function getMySuppliers(dispatch,getState, id, page, filter){
  return apiCall(dispatch,getState, "POST",'/vendors/myvendors/'+ id + '/' + page, filter).then(data =>{
    return data
  })
} 
//Inventory Locations
export function getInventoryLocations(dispatch, getState, data){
  return apiCall(dispatch,getState,'POST',`/inventoryLocations/getLocations`, data);
} 
export function createFormFromAgentFile(dispatch, getState, data) {
  return apiCall(dispatch, getState, "POST", "/formbuilder/createFormFromAgentFile", data);
}
